<template>
  <div>
    <BatchList active="second"></BatchList>
  </div>
</template>
<script>
import BatchList from './components/batchList.vue'
export default {
  name: 'hnCodesGrant',
  components: {
    BatchList
  },
  data() {
    return {
    }

  },
  methods: {
  }
}
</script>